import React, { Component } from 'react';
import { UserService } from '../service/UserService';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import {Button} from 'primereact/button';

export class Login extends Component {

    constructor(props) {
        super(props);

        UserService.logout();

        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            error: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { username, password, returnUrl } = this.state;

        // stop here if form is invalid
        //if (!(username && password)) {
        if (!username) {
            return;
        }

        this.setState({ loading: true });
        UserService.login(username, password);
        const { from } = /*this.props.location.state ||*/ { from: { pathname: "/events" } };    // navigate to observation dates page after login
        this.props.history.push(from);

        /*
        UserService.login(username, password)
            .then(
                user => {
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                },
                error => this.setState({ error, loading: false })
            );
            */
    }

    render() {
        const { username, password, submitted, loading, error } = this.state;
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <h1>Login</h1>
                        <br/>

                        <form name="form" onSubmit={this.handleSubmit}>
                            <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                                <span className="p-float-label">
                                    <InputText id="username" name="username" onChange={this.handleChange} value={username} required minLength={3} />
                                    <label htmlFor="username">Username</label>
                                </span>
                            </div>
                            <br/>
                            <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                <span className="p-float-label">
                                   <Password id="password" name="password" value={this.state.password} onChange={this.handleChange} feedback={false}/>
                                    <label htmlFor="password">Password</label>
                                </span>
                            </div>
                            <br/>
                            <div className="form-group">
                                <Button label="Login" icon="pi pi-check" disabled={loading}/>
                            </div>
                            {error &&
                                <div className={'alert alert-danger'}>{error}</div>
                            }
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}