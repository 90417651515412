import React, { Component } from 'react';

export class Activities extends Component {

    constructor() {
        super();
        this.state = {
            data: 0
        };
    }

    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <h1>Aktivitätenliste</h1>
                        <p>
                            TODO
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}