import React, { Component } from 'react';
import { EventService } from '../service/EventService';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';

export class Events extends Component {

    constructor() {
        super();
        this.state = {
            dataViewValue: [],
            //selectedEvent: null,
            layout: 'grid',
            sortOptions: [
                { label: 'Newest First', value: '!year' },
                { label: 'Oldest First', value: 'year' },
                { label: 'Brand', value: 'brand' }
            ],
        };
        this.eventService = new EventService();
        this.dataViewItemTemplate = this.dataViewItemTemplate.bind(this);
        
        //localStorage.removeItem('selectedEvent');
    }

    componentDidMount() {
        this.eventService.getEvents().then(events => this.setState({ dataViewValue: events }));
    }

    selectEvent(e, event) {
        localStorage.setItem('selectedEvent', JSON.stringify(event));
        const { from } = /*this.props.location.state ||*/ { from: { pathname: "/" } };    // navigate to dashboard after login
        this.props.history.push(from);
    }

    dataViewItemTemplate(event, layout) {
        if (!event) {
            return;
        }

        if (layout === 'grid') {
            return (
                <div style={{ padding: '.5em' }} className="p-col-12 p-md-3">
                    <Panel header={event.shopName} style={{ textAlign: 'center' }}>
                        <div className="car-detail">{event.from}<br />-<br />{event.to}</div>
                        <Button icon="pi pi-check" onClick={(e) => this.selectEvent(e, event)} />
                    </Panel>
                </div>
            );
        }
    }

    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>Terminauswahl</h1>
                        <DataView ref={el => this.dv = el} value={this.state.dataViewValue} itemTemplate={this.dataViewItemTemplate} layout={this.state.layout} />
                    </div>
                </div>
            </div>
        );
    }
}