import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import PropTypes from 'prop-types';

export class AppTopbar extends Component {

    static defaultProps = {
        onToggleMenu: null
    }

    static propTypes = {
        onToggleMenu: PropTypes.func.isRequired
    }

    render() {
        let user = JSON.parse(localStorage.getItem('user'));
        let selectedEvent = JSON.parse(localStorage.getItem('selectedEvent'));
        
        let localStorageData = "/";
        if (user)
            localStorageData += " " + user.username + " /";
        if (selectedEvent)
            localStorageData += " " + selectedEvent.shopName + " /";
        
        return (
            <div className="layout-topbar clearfix">
                <button className="p-link layout-menu-button" onClick={this.props.onToggleMenu}>
                    <span className="pi pi-bars" />
                </button>
                
                <div className="layout-topbar-icons">{localStorageData}</div>

                {/*
                <div className="layout-topbar-icons">
                    <span className="layout-topbar-search">
                        <InputText type="text" placeholder="Search" />
                        <span className="layout-topbar-search-icon pi pi-search" />
                    </span>
                    <button className="p-link">
                        <span className="layout-topbar-item-text">Events</span>
                        <span className="layout-topbar-icon pi pi-calendar" />
                        <span className="layout-topbar-badge">5</span>
                    </button>
                    <button className="p-link">
                        <span className="layout-topbar-item-text">Settings</span>
                        <span className="layout-topbar-icon pi pi-cog" />
                    </button>
                    <button className="p-link">
                        <span className="layout-topbar-item-text">User</span>
                        <span className="layout-topbar-icon pi pi-user" />
                    </button>
                </div>
                */}
            </div>
        );
    }
}